/*引入vue-*/
import {createRouter, createWebHashHistory} from "vue-router"
import {getChildrens} from '@/router/common'
import AdminLayout from '@/layouts/admin-layout'
import config from "@/config";


/*
* meta元属性
*
* cache，代表页面是否缓存(true,缓存），默认不写为false
* icon，菜单的图标
* role，页面权限
* menu，是否作为菜单，默认不写为true
* */
const children = getChildrens();

//路由页面
let routes = [
    {
        path: "/",
        name: " ",
        meta: {
            role: 0
        },
        redirect: config.index,
        component: AdminLayout,
        children: [
            {
                path: "index",
                meta: {
                    role: 2,
                    name: "录单首页"
                },
                component:()=>import('@/pages/main'),
                redirect:'/index/main',
                children: children.录单首页.default()
            },

            {
                path: "dash",
                meta: {
                    role: 2,
                    name: "工作台"
                },
                redirect:'/dash/task',
                component: () => import('@/layouts/admin-view.vue'),
                children: children.工作台.default()
            },
            {
                path: "admin",
                meta: {
                    role: 3,
                    name: "系统管理"
                },
                component: () => import('@/layouts/admin-view.vue'),
                redirect:'/admin/user',
                children: children.系统管理.default()
            },
        ]
    },
    {
        path: "/login",
        meta: {
            role: 0,
            name: "登录"
        },
        component: () => import("@/pages/login"),
    },
    {
        path: '/403',
        meta: {
            role: 0,
            name: '403'
        },
        component: () => import('@/pages/error/403'),
    },
    {
        path: '/:pathMatch(.*)*',
        meta: {
            role: 0,
            name: '404'
        },
        component: () => import('@/pages/error/404'),
    }
];

/*路由配置项*/
export const router = createRouter({
    history: createWebHashHistory(),
    routes
});

