<template>
  <div class="admin-message">
    <a-popover overlayClassName="ant-popover-placement-self" placement="top">
      <template #content>
        暂无消息
      </template>
      <v-icon icon="BellOutlined" size="16px"/>
    </a-popover>
  </div>
</template>

<script>

</script>

<style scoped lang="scss">
.admin-message {
  @include flex-center;
  justify-content: center;

  width: 30px;
  height: 30px;

  margin-right: 25px;
  padding: 5px;
  border-radius: 50%;

  color: $sub-text;

  border: 1px solid $border;
}
</style>