<script>
/**
 * @author 友人a丶
 * 批量生成表单
 * 遇到的问题
 * 1. 传递进来双向绑定的数据必须作为一个data对象单独传递进行，跟表单配置对象 作为配置中的一个值会导致替换整个proxy对象，丢失响应性
 * 2. options进行动态加载响应式的时候使用ref会导致组件报类型错误
 * 3. item.attr.value = props.data[item.key];  //依赖没有被收集
 * 4. h内部使用时，直接传入一个定义的对象，才会被收集响应式依赖，可以通过assign去合并，会保持响应式
 *
 * 组件会暴露一个formDom的ref用于表单验证
 * 提供了一个check属性方法，用于权限验证
 * */

import {
  Input,
  InputNumber,
  InputPassword,
  DatePicker,
  RangePicker,
  Select,
  Form,
  FormItem,
  Row,
  Col,
  Checkbox,
  Button,
  RadioGroup,
  Cascader,
  Textarea
} from 'ant-design-vue'


import RangeNumber from './v-form/v-range-number.vue'
import {decode} from 'js-base64'

import Icon from './v-icon.vue'
import {h, watch, isRef, toRaw} from 'vue'
import {cloneDeep, debounce} from 'lodash'
import axios from "axios";
import load from "@/common/load";
import {store} from "@/common/common";

/*
* 表单组件库
* */
let components = {
  'input': Input,
  "input-number": InputNumber,
  "input-password": InputPassword,
  'data-picker': DatePicker,
  'range-picker': RangePicker,
  'select': Select,
  'checkbox': Checkbox,
  "input-range": RangeNumber,
  "radio": RadioGroup,
  "cascader": Cascader,
  "textarea": Textarea
}


/*
* 不作为输入星组件渲染
* */
let checked = ['checkbox'];

/**
 * base64转换
 * @param data
 * @returns {*[]}
 */
function decode_base(data) {
  let result = [];

  for (let i of data) {
    i.title = decode(i.title);
    i.key = decode(i.key);
    if (i.children) {
      i.children = decode_base(i.children);
    }
    result.push(i);
  }
  return result;
}


/**
 *
 * @param api 数据接口地址
 * @param options 选项的响应式变量
 * 加载数据的接口
 */
let loadOptions = {
  /*下拉框初始化数据*/
  select(api, options) {
    axios.get(api)
        .then(res => {
          if (res.data.code) {

            /*
            * 如果长度为0
            * */
            if (res.data.data.length == 0) {
              options.data = [];
              return;
            }

            /*
            * 获取对象的所有键
            * */
            let keys = Object.keys(res.data.data[0]);

            let data = res.data.data.map((item) => {

              let need = {
                label: item[keys[1]],
                value: item[keys[0]],
              };

              /*
              * 如果元素个数大于2
              * 则
              * 复制属性
              * */
              if (keys.length > 2) {
                for (let i = 2; i < keys.length && i < 5; i++) {
                  need[keys[i]] = item[keys[i]];
                }
              }

              return need;

            })

            options.save(data); //保存快照
            options.data = data; //保存数据

          } else {
            load.error(res.data.errMsg);
          }
        }).catch(e => {
      load.error(e.message);

    })
  },
  /*级联下拉加载数据*/
  cascader(api, options, base64) {
    axios.get(api)
        .then(res => {
          if (res.data.code) {
            /*
            * 如果长度为0
            * */
            if (res.data.data.length == 0) {
              options.data = [];
              return;
            }
            let data = base64 ? decode_base(res.data.data) : res.data.data; //解码

            options.save(data); //保存快照
            options.data = data; //保存数据

          } else {
            load.error(res.data.errMsg);
          }
        }).catch(e => {
      load.error(e.message);

    })
  },

}


/*
* 每一个非文本的子元素都用函数返回
* 返回单个vNode或者VNode的数组
* */


export default {
  props: {
    /*表单*/
    form: {
      type: Object,
      required: true
    },
    /*数据源*/
    data: {
      type: Object,
      required: true
    },
    /*布局*/
    layout: {
      type: String,
      default: "horizontal"
    },
    /*间隔*/
    gutter: {
      type: [Number, Object, Array],
      default: 16
    },
    /*显示重置按钮*/
    showReset: {
      type: Boolean,
      default: false
    },
    /*默认布局*/
    grid: {
      type: Object,
      default: () => {
        return {
          xxl: 5,
          xl: 7,
          lg: 8,
          md: 10,
          sm: 12,
          xs: 24,
        }
      }
    },
    /*label是否显示边框*/
    showBorder: {
      require: false,
      default: true
    },
    /*权限验证*/
    check: {
      require: false,
      default: null,
      type: Function
    },
    /*label宽度如何处理,fixed固定，fit自适应文字*/
    label: {
      required: false,
      default: "fit"
    }
  },
  emits: ['change'],
  setup(props, {emit}) {

    /*
    * 自定义事件监听
    * */
    watch(() => props.data, () => emit("change", props.data), {deep: true});

    let cached = null; //缓存组件

    /*
    * 开始渲染
    * */

    /*
    * 水平模式下，无关padding和border
    * */
    let showBorder = props.showBorder && props.layout != "vertical";
    let hasPadding = (props.label !== 'fixed') && props.layout != "vertical";

    return () => h(
        /*Form组件*/
        Form,
        {
          ref: 'formDom',
          model: props.data,
          labelAlign: "left",
          layout: props.layout,
          class: props.layout + (showBorder ? " show-border" : "") + (hasPadding ? " has-padding" : ""),
          colon: false
        },
        /*
        * 渲染表单内部的元素
        * */
        () => h(
            /*
            * Row组件
            * Colums 之间的间隔
            * */
            Row, {gutter: props.gutter},
            /*
            * 遍历所有需要渲染的组件
            * */
            () => {


              /*
            * 防止组件重复被渲染
            * 缓存
            * */
              if (cached != null) {
                return cached;
              }

              /*
              * 子元素
              * */
              let parent = []; //最终结果

              /*
              * 是否需要权限验证
              * */
              if (!!props.check) {
                parent = props.form.filter((item) => {
                  return props.check(item);
                })
              } else {
                parent = props.form;
              }


              let children = []; //最终结果

              for (let i = 0; i < parent.length; i++) {

                let item = parent[i]; //配置

                /*如果定义了偏移位置*/
                if (i % 4 === 0 && i != 0) {
                  children.push(h(Col, Object.assign({
                    xxl: 4,
                    xl: 0,
                    lg: 0,
                    md: 0,
                    sm: 0,
                    xs: 0
                  }, !item.offset ? {} : item.offset)));
                }


                /*
                * 如果没有属性对象
                * 则创建一个
                * 创建属性对象
                * */
                if (!item.attr) {
                  item.attr = {};
                }

                /*
                * 过滤基础属性
                * 默认可清除
                * */
                // eslint-disable-next-line no-prototype-builtins
                if (!item.attr.hasOwnProperty('allowClear')) {
                  item.attr.allowClear = true; //默认允许清空
                }


                /*
                * 处理单选，多选框的不一致性
                * */
                if (checked.indexOf(item.type) >= 0) {

                  /*
                  * 如果需要双向绑定
                  * */
                  if (!item.bind) {
                    item.attr['onUpdate:checked'] = (value) => {
                      props.data[item.key] = value;
                    }
                  }

                  children.push(h(Col,
                      Object.assign({}, !item.grid ? {} : item.grid),
                      () => h(FormItem, {},
                          () => h(components[item.type],
                              Object.assign({checked: props.data[item.key]}, item.attr),
                              () => item.text)
                      )));

                  continue;

                } else {


                  /*
                  * 如果需要双向绑定
                  * */
                  if (!item.bind) {
                    item.attr['onUpdate:value'] = (value) => {
                      props.data[item.key] = value;
                    }
                  }


                  /**
                   * 如果没有默认的options并且指定了接口
                   * */
                  let options = store([]); //标记是否需要合并options

                  // eslint-disable-next-line no-prototype-builtins
                  if (!item.attr.hasOwnProperty('options') && item.hasOwnProperty('data')) {

                    loadOptions[item.type](item.data, options, item.base64); //异步加载选项数据

                    /*
                    * 如果需要级联筛选
                    * 根据指定的key值动态筛选
                    * */
                    // eslint-disable-next-line no-prototype-builtins
                    if (item.hasOwnProperty("filter")) {

                      /*
                      * 动态响应依赖的选项值
                      * */
                      watch(() => props.data[item.filter.depend],
                          debounce(() => {


                            console.log("触发依赖")
                            /*
                            * 如果依赖的键没有值
                            * */
                            if (props.data[item.filter.depend].length == 0) {
                              options.reset(); //重置数据到快照
                              return false;
                            }


                            options.reset(); //重置数据到快照
                            options.data = options.data.filter((option) => {
                              /*
                              * 数组和非数组
                              * */
                              if (Array.isArray(props.data[item.filter.depend])) {
                                return props.data[item.filter.depend].indexOf(option[item.filter.key]) > -1;
                              } else {
                                return props.data[item.filter.depend] == option[item.filter.key];
                              }
                            })

                          }, 500), {
                            deep: true
                          })
                    }


                  }

                  /*
                    xs	<576px
                    md	≥768px
                    lg	≥992px
                    xl	≥1200px
                    xxl	≥1600px
                    xxxl	≥2000px
                    */

                  children.push(h(Col,
                      Object.assign(
                          cloneDeep(props.grid),
                          !item.grid ? {} : item.grid),
                      () => h(FormItem,
                          Object.assign({
                                /*
                                * 垂直布局时直接百分百即可
                                * */
                                label: item.label,
                                name: item.key,
                                class: {noborder: item.type == "radio"},
                                rules: {required: !!item.attr.required, message: item.attr.placeholder}
                              },
                              /**
                               * label标签是固定宽度还是自适应文字
                               */
                              props.label == "fixed" ? {
                                "label-col": {span: (props.layout == "vertical" ? 24 : 7)},
                                "wrapper-col": {span: (props.layout == "vertical" ? 24 : 17)},
                              } : {}),
                          /*内部子元素*/
                          () => h(
                              components[item.type],
                              Object.assign(
                                  {
                                    value: props.data[item.key]
                                  }, item.attr,
                                  isRef(item.attr.options) ? {options: item.attr.options.value} : {},
                                  options.data.length == 0 ? {} : {options: options.data}))
                      )));


                  continue;

                }


              }

              /**
               * 判断是否需要显示重置按钮
               */
              if (props.showReset) {

                /*
                * 深拷贝初始数据对象
                * 必须toRaw
                * */
                let origin = cloneDeep(toRaw(props.data));


                children.push(
                    h(Button,
                        {
                          type: "dashed",
                          style: "margin-bottom:15px;",
                          onClick() {
                            Object.assign(props.data, origin); //重置
                          }
                        },
                        {default: () => "重置筛选", icon: () => h(Icon, {icon: "RedoOutlined", size: "12px"})}))
              }

              cached = children;//缓存
              return children;

            }
        ));
  }
}


</script>

<style scoped lang="scss">

/*筛选的表单*/
.horizontal {
  margin: 5px 0 0;

  :deep(.ant-form-item) {

    display: flex;
    align-items: flex-start;
    width: 100%;
    margin: 0 0 16px 0;


    /*左标签*/
    .ant-form-item-label-left {
      @include flex-center;
      justify-content: center;
      flex-shrink: 0;
      height: 32px;

      .ant-form-item-label {
        height: fit-content;
      }

      .ant-form-item-no-colon {
        &:after {
          display: none;
        }
      }
    }


    /*日期选择*/
    .ant-picker {
      width: 100%;
    }

    /*强制单选组不换行*/
    .ant-radio-group {
      white-space: nowrap;
    }

    /*选择框去除做圆角*/
    .ant-select-selector, .ant-input-affix-wrapper, .ant-picker, .ant-input-number {
      width: 100%;
      height: 32px;
    }
  }


  .noborder {
    :deep(.ant-form-item-label-left) {
      border: none;
    }
  }

  /*
    解决小屏版面异常的问题
  */
  @media (max-width: 575px) {
    :deep(.ant-form-item) {
      flex-wrap: nowrap;

      .ant-form-item-label-left {
        padding: 0;
        max-width: 29.16666667%;
      }

      .ant-form-item-control {
        max-width: 70.83333333%;
      }

      .ant-checkbox-wrapper {
        span {
          white-space: nowrap;
        }
      }

    }
  }
}

/*筛选的表单*/
.vertical {

}

.show-border {

  :deep(.ant-form-item) {

    .ant-form-item-label-left {
      border: 1px solid $border-form-item;
      border-right: none;
      border-bottom-left-radius: 4px;
      border-top-left-radius: 4px;
    }

    /*选择框去除做圆角*/
    .ant-select-selector, .ant-input-affix-wrapper, .ant-picker, .ant-input-number {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }

  }
}

/*是否拥有padding*/
.has-padding {
  :deep(.ant-form-item) {
    /*左标签*/
    .ant-form-item-label-left {
      padding: 0 15px;
    }
  }

}

</style>