/*
* @author 友人a丶
* @date 2022-07-11
*
* 加载全局路由守卫
* */


import {router} from "@/router";
import userStore from "@/stores/user";
import load from "@/common/load";
import systems from "@/stores/system";
import NProgress from 'nprogress'
import loadUser from "@/service/load-user";
import config from '@/config'

/*进度条*/
NProgress.configure({showSpinner: false})


// 不需要拦截的路由配置
const ignoreRoute = {
    names: ['404', '403'],      //根据路由名称匹配
    paths: ['/login'],   //根据路由fullPath匹配
    /**
     * 判断路由是否包含在该配置中
     * @param route vue-router 的 route 对象
     * @returns {boolean}
     */
    includes(route) {
        return ignoreRoute.names.includes(route.name) || ignoreRoute.paths.includes(route.path)
    }
}


/*
* 加载路由守卫
* */
export default function () {

    console.log("加载路由守卫...");
    let user = userStore();//全局状态


    /*
    * 加载进度条
    * */
    router.beforeEach((to, from, next) => {
        // start progress bar
        if (!NProgress.isStarted()) {
            NProgress.start()
        }
        next()
    });

    /*
    * 判断系统是否初始化
    * */
    router.beforeEach(async (to, from, next) => {
        if (!systems().loaded) {
            await loadUser();//加载用户信息初始化系统

            /*如果是商家*/
            if (user.role == 1) {
                next({path: '/index/order'});
                return;
            }
        }
        next(); //下一个

    });


    /*
    * 已登录时，访问登录页面，让它走
    * */
    router.beforeEach((to, from, next) => {
        if (user.role != 0 && (to.path == "/login")) {
            next(config.index);
        } else {
            next(); //下一个
        }
    });


    /*
    * 判断是否登录
    * */
    router.beforeEach((to, from, next) => {

        /*
        * 判断是否需要拦截
        * */
        console.log("登录判断守卫激活....")

        if (ignoreRoute.includes(to)) {
            next();
        } else {
            /*
            * 角色为0，代表未登录
            * */
            if (user.role == 0) {
                next({path: '/login'});
            } else {
                next();
            }

        }
    })


    /*
    * 判断用户权限
    * */
    router.beforeEach((to, from, next) => {

        console.log("权限判断守卫激活....")
        /*
        * 判断是否需要拦截
        * */
        if (ignoreRoute.includes(to)) {
            next();
        } else {
            /*
            * 判断用户权限
            * */
            if (user.role < to.meta.role) {
                load.error("您无权限访问该页面....");
                next({path: '/403'});
            } else {
                next();
            }

        }


    })


    /*
    * 切换页面标题
    * */
    router.beforeEach((to, from, next) => {
        document.title = to.meta.name
        next();
    })


    /*
    * 结束进度条
    * */
    router.afterEach(() => {
        // finish progress bar
        NProgress.done()
    });


}

