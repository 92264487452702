/*
* @author 友人a丶
* @date 2022-07-11
*
* 加载axio拦截器
* */
import axios from "axios";
import load from "@/common/load";
import apis from '@/service/api';
import quitSystem from './quit-system'
import config from "@/config";

/*
* 是否需要注册认证头
* */
if(config.auth.mode == "token"){
    // 登录的Token保存
    const xsrfHeaderName = config.auth.token; //认证头的名字
    axios.defaults.timeout = 5000;
    axios.defaults.withCredentials= true;
    axios.defaults.xsrfHeaderName= xsrfHeaderName;
    axios.defaults.xsrfCookieName= xsrfHeaderName;
}


// 不需要拦截的接口
const ignoreApi = {
    api: [
        apis.login
    ],
    includes(api) {

        /*
        * 判断当前请求的接口是否在忽视的列表
        * */
        for(let item of ignoreApi.api){
            let reg=new RegExp(`.*${item}.*`);
            if(reg.test(api)){
                return true;
            }
        }
        return false;
    }
}

/*
* 注册响应拦截器
* */
export default function (){
    console.log("加载拦截器...");

    axios.interceptors.response.use(function (res){
        console.log("请求接口："+res.config.url)
        console.log(res);

        /*
        * 判断是否需要拦截
        * */
        if(ignoreApi.includes(res.config.url)){
            return res;
        }

        /*
        * 判断用户登录是否失效
        * */
        if(res.code == -1){

            load.confirm("当前登录状态已失效，请您重新登录！",()=>{
                quitSystem(false); //退出登录
            })
        }

        return res;
    });


}
