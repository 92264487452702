<template>
  <a-modal @cancel="visible=false" @ok="modify" :maskClosable="false" centered :title="title"
           :visible="visible">
    <v-batch
        ref="formDom"
        label="fixed"
        layout="horizontal"
        :grid="{ xxl: 24,xl: 24, lg: 24,md: 24,sm: 24, xs: 24}"
        :form="form" :data="need.data"/>
  </a-modal>
</template>

<script setup>
/*
* visible弹出的
* */
import load from "@/common/load";
import axios from "axios";
import {inject,ref} from "vue";
import {switchForm} from "@/common/common";

let props = defineProps(["title", "name", "init"]);
let visible = inject("visible_" + props.name); //是否显示修改框
let table = inject("table"); //重载表格
let form = inject("form_" + props.name); //修改表单模板
let need = inject("need_" + props.name); //接口
let formDom = ref(null); //表单dom

/*
* 添加用户
* */
const modify = () => {

  formDom.value.$refs.formDom.validate().then(() => {
    /* 显示加载效果 */
    load.loading("修改中...");

    /* 开始请求 */
    axios.post(props.init, switchForm(need.data))
        .then((res) => {
          /*
          * 判断请求结果
          * */
          if (res.data.code) {
            load.success("修改成功！");
            visible.value = false;
            table.loadData(); //重新加载表格数据
          } else {
            /* 弹出错误原因 */
            load.error(res.data.errMsg);
          }
        }).catch((e) => {
      /* 弹出错误原因 */
      load.error(e.message);
    }).finally(() => {
      /* 关闭加载效果 */
      load.loaded();
    });

  }).catch((e) => {
    load.error(e.message)
    return
  })

}

</script>
