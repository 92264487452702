
/*
* @author 友人a丶
* @date 2022-07-11
*
* 系统是否初始化成功
* */

import {defineStore} from "pinia";

export default defineStore('loaded',{
    state(){
        /*初始数据列表*/
        return {
           loaded:false
        }
    },
    actions:{
        /*
        * 系统加载完成
        * */
        load(){
            this.loaded=true;
        }
    }
})