export default function () {
    return [
        {
            path: 'main',
            meta: {
                role: 2,
                name: '录单首页'
            },
            component: () => import('@/pages/main/main')
        },
        {
            path: 'shop/:id',
            props: true,
            meta: {
                role: 2,
                name: '店铺首页'
            },
            component: () => import('@/pages/main/order')
        },
        {
            path: 'order',
            props: true,
            meta: {
                role: 1,
                menu: false,
                name: '店铺首页'
            },
            component: () => import('@/pages/main/shop')
        },
    ];
}