<template>
  <slot></slot>
  <a-modal
      @cancel="visible=false"
      :width="width"
      @ok="operate"
      :maskClosable="false"
      centered
      :title="title"
      :visible="visible">

    <div class="flex">
      <v-batch
          ref="formDom"
          layout="horizontal"
          :grid="{ xxl: 24,xl: 24, lg: 24,md: 24,sm: 24, xs: 24}"
          :form="form" :data="need.data"/>

      <slot name="remark"/>

    </div>
  </a-modal>
</template>

<script setup>
/*
* 点击的
* */

import load from "@/common/load";
import axios from "axios";
import {inject,ref} from "vue";
import {switchForm} from "@/common/common";

let props = defineProps({
  title: {
    required: false
  },
  init: {
    required: false
  },
  width: {
    required: false,
    default: "520px"
  },
  form: {
    type: Object,
    required: true
  },
  need: {
    type: Object,
    required: true
  },
  visible: {
    type: Object,
    required: true
  },
  type: {
    type: Number,
    required: false,
    default: 1
  },
});

let table = inject("table");
let formDom = ref(null);
/*
* 添加用户
* */
const operate = () => {

  formDom.value.$refs.formDom.validate().then(() => {
    /* 显示加载效果 */
    load.loading("操作中...");

    /* 开始请求 */
    axios.post(props.init, switchForm(props.need.data))
        .then((res) => {
      /*
      * 判断请求结果
      * */
      if (res.data.code) {
        load.success("添加成功！");
        props.visible.value = false;
        table.loadData(); //重新加载表格数据
        if (props.type == 1) {
          props.need.reset(); //重置数据
        }
      } else {
        /* 弹出错误原因 */
        load.error(res.data.errMsg);
      }
    }).catch((e) => {
      /* 弹出错误原因 */
      load.error(e.message);
    }).finally(() => {
      /* 关闭加载效果 */
      load.loaded();
    });

  }).catch((e) => {
    load.error(e.message)
    return
  })

}

</script>

<style scoped lang="scss">
.flex {
  @include flex-center;
  align-items: flex-start;

  .flex-right {
    flex-shrink: 0;
    width: 30%;
  }
}
</style>
