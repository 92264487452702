/*
* @author 友人a丶
* @date 2022-07-11
*
* 基本的网络请求接口封装
*
* */


import axios from "axios";
import api from './api';


/*
  * 用户登录验证
  * @param data Object => username 用户名 。password  用户密码
  * */
export function login(data = '', mode = 1) {

    return new Promise(resolve => {
        axios.post(mode == 1 ? api.login.user : api.login.shop, data)
            .then((res) => {
                /*
                * 判断请求结果
                * */
                resolve(res.data);
            }).catch((e) => {


            resolve({
                code: 0,
                errMsg: e.message
            })
        })
    })

}