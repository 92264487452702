<template>
  <div class="admin-title" @click="$router.push(config.index)"
       :style="{width:collapsed?collapsedWidth+'px':expandWidth+'px'}">
    <div class="logo">
      <img :src="config.logo"/>
    </div>
    <div class="title" :style="{width:collapsed?'0':'110px'}">
      <h1>
        {{ config.title }}
      </h1>
    </div>
  </div>
</template>

<script setup>
import config from '@/config';
import setting from "@/stores/setting";
import {storeToRefs} from "pinia";

/*是否收缩*/
let {
  collapsed,
  collapsedWidth,
  expandWidth
} = storeToRefs(setting());

</script>

<style lang="scss" scoped>

.admin-title {

  @include flex-center;
  flex-shrink: 0;

  height: 100%;
  transition: width 0.2s ease;

  cursor: pointer;

  .logo {
    height: 100%;
    flex-shrink: 0;
    @include flex-center;
    justify-content: flex-start;
    padding: 0 12px 0 25px;

    img {
      height: 50%;
      width: 32px;
    }
  }

  .title {

    width: 110px;
    transition: all 0.2s ease;
    overflow: hidden;

    h1 {
      font-size: 18px;
      font-weight: bold;
      white-space: nowrap;
      margin: 0;
    }
  }

}

</style>