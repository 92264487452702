export default function () {
    return [
        {
            path: 'task',
            meta: {
                role: 2,
                name: '个人任务',
                icon:"AuditOutlined"
            },
            component: () => import('@/pages/dash/task')
        },
        {
            path: 'order',
            meta: {
                role: 2,
                name: '订单查询',
                icon:'MoneyCollectOutlined'
            },
            component: () => import('@/pages/dash/order')
        },
    ];
}