/*
* @author 友人a丶
* @date 2022-07-11
*
* 初始化用户信息
* */


import load from "@/common/load";
import {login} from "@/service/requests";
import user from "@/stores/user";
import systems from "@/stores/system";


export default function () {

    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {

        load.loading("系统正在初始化....");
        console.log("加载用户信息...");

        let result = await login(); //请求初始化登录
        load.loaded(); //加载完毕

        /*
        * 获取失败
        * 跳转登录界面
        * */
        if (result.code) {
            user().save(result.data);
            resolve(true); //返回
        }

        /*
        * 系统初始化完毕
        * */

        systems().load();//已初始化
        resolve(false); //返回


    });
}