import {createApp} from 'vue'
import App from './App.vue'
import {createPinia} from "pinia"
import {router} from "./router/index";

import batch____import from '@/common/import'
import goto_____bootstrap from "./utils/bootstrap";


//import * as mock from './mock';//接口模拟



/* 初始化vue */
let app = createApp(App);
app.use(createPinia());//状态管理器
app.use(router);//路由加载

batch____import(app);//批量导入组件
goto_____bootstrap(); //引导系统初始化

app.mount('#app')
