/*
* @author 友人a丶
* @date 2022-07-11
*
* 保存登录后的用户信息
* */

import {defineStore} from "pinia";
import Cookies from "js-cookie";
import config from "@/config";

export default defineStore('user', {
    state() {
        /*初始数据列表*/
        return {
            "nickname": "", //昵称
            "mobile": "", //手机号
            "avatar": "", //头像
            "role": 0, //角色1,2,3
            "token": "", //如果有token
            "expireAt": 0, //token有效期
            "options": {
                title: "订单管理"
            } //系统的公告
        }
    },
    actions: {
        /*
        * 保存用户信息
        * */
        save(state) {

            this.$state = state;//保存用户信息

            /*如果是token模式*/
            if (config.auth.mode == "token") {
                Cookies.set(config.auth.token, state.token, {expires: state.expireAt}); //保存token
            }
        }
    }
})