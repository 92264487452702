<template>
  <a-config-provider :locale="zhCN">
    <router-view/>
  </a-config-provider>
</template>

<script setup>
/*引入全局中文语言包*/
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';

dayjs.locale('zh');

</script>

<style lang="scss">

body {
  margin: 0;
  padding: 0;
  width: 100% !important;
  background-color: #f6f9f9 !important;

  /*
    处理滚动条导致的抖动问题
  */
  @include scroll-bar;
  overflow-y: scroll!important;
  &::-webkit-scrollbar-thumb {
    display: block;
  }

  .ant-modal-centered {
    @include scroll-bar;
    overflow-y: scroll!important;

    &::-webkit-scrollbar-thumb {
      display: block;
    }
  }

  #app {
    width: 100%;
    height: 100%;
  }


  table td{
    font-family: DINPro, PingFang SC, -apple-system, BlinkMacSystemFont, Segoe UI, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", Segoe UI Symbol !important;
  }
}
</style>
