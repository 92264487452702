<template>
  <div @click="visible=true">
    <slot></slot>
  </div>
  <a-modal @cancel="visible=false" :width="width" @ok="add" :maskClosable="false" centered :title="title"
           :visible="visible">
    <div class="flex">
      <v-batch
          label="fixed"
          ref="formDom"
          layout="horizontal"
          :grid="{ xxl: 24,xl: 24, lg: 24,md: 24,sm: 24, xs: 24}"
          :form="form" :data="need.data"/>
      <slot name="remark"/>
    </div>
  </a-modal>
</template>

<script setup>
import load from "@/common/load";
import axios from "axios";
import {inject,ref} from "vue";
import {switchForm} from "@/common/common";

let props = defineProps({
  title: {
    required: false
  },
  /*
  * 依赖的命名
  * */
  name: {
    required: false
  },
  /*
  * 接口地址
  * */
  init: {
    required: false
  },
  width: {
    required: false,
    default: "520px"
  },

  /*
  * 网络请求的回调
  * */
  hook: {
    required: false,
    default: null
  }
});

let visible = ref(false);

let form = inject("form_" + props.name);
let table = inject("table");
let need = inject("need_" + props.name);
let formDom = ref(null);


let hook;/*hook处理*/
if (props.hook) {
  hook = inject(props.hook);
}
/*
* 添加用户
* */
const add = () => {

  formDom.value.$refs.formDom.validate().then(() => {
    /* 显示加载效果 */
    load.loading("操作中...");


    /* 开始请求 */
    axios.post(props.init, switchForm(need.data))
        .then((res) => {
          /*
          * 判断请求结果
          * */
          if (res.data.code) {
            load.success("添加成功！");
            visible.value = false;
            table.loadData(); //重新加载表格数据
            need.reset(); //重置数据

            /*师傅传递了hook*/
            if (props.hook) {
              hook.hook(); //触发钩子
            }


          } else {
            /* 弹出错误原因 */
            load.error(res.data.errMsg);
          }
        }).catch((e) => {
      /* 弹出错误原因 */
      load.error(e.message);
    }).finally(() => {
      /* 关闭加载效果 */
      load.loaded();
    });
  }).catch((e) => {
    load.error(e.message)
    return
  })

}

</script>

<style scoped lang="scss">
.flex {
  @include flex-center;
  align-items: flex-start;

  .flex-right {
    flex-shrink: 0;
    width: 30%;
  }
}
</style>
