export default function () {
    return [
        {
            path: 'user',
            meta: {
                role: 3,
                name: '员工管理',
                icon: 'ProjectOutlined'
            },
            component: () => import('@/pages/admin/user')
        },
        {
            path: 'shop',
            meta: {
                role: 3,
                name: '店铺管理',
                icon: 'SolutionOutlined'
            },
            component: () => import('@/pages/admin/shop')
        },
        {
            path: 'fund',
            meta: {
                role: 3,
                name: '推荐费用',
                icon: 'UserOutlined'
            },
            component: () => import('@/pages/admin/fund')
        },
        {
            path: 'shoptotal',
            meta: {
                role: 3,
                name: '店铺订单',
                icon: 'SolutionOutlined'
            },
            component: () => import('@/pages/admin/shopTotal')
        },
        {
            path: 'total',
            meta: {
                role: 3,
                name: '员工订单',
                icon: 'PieChartOutlined'
            },
            component: () => import('@/pages/admin/total')
        },
        {
            path: 'debt',
            meta: {
                role: 3,
                name: '系统设置',
                icon: 'TrademarkCircleOutlined'
            },
            component: () => import('@/pages/admin/set')
        },
        {
            path: 'log',
            meta: {
                role: 3,
                name: '系统日志',
                icon: 'ApartmentOutlined'
            },
            component: () => import('@/pages/admin/log')
        },
    ];
}