<template>
  <div class="table" ref="table">
    <a-table
        :rowKey="key"
        :sticky="sticky"
        :dataSource="data.data.data"
        :columns="column"
        :loading="loadingTable"
        :pagination="!pagination?pagination:paginate"
        @change="loadData"
        size="default"
        @resizeColumn="handleResizeColumn"
        :scroll="{x: 'max-content'}"
        :rowSelection="rowSelection===false?null:(rowSelection === true?selectConfig:rowSelection)"
        :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
    >

      <!--客户展开的详情-->
      <template v-if="$slots.expandedRowRender" #expandedRowRender="{ record }">
        <slot name="expandedRowRender" :record="record"/>
      </template>

      <template #bodyCell="{text,record, index, column}">
        <slot name="bodyCell" :text="text" :column="column" :index="index" :record="record"/>
      </template>

      <template #summary>
        <a-table-summary fixed="top">
          <slot :data="data.data" name="summary" v-if="data.data.data.length > 0"/>
        </a-table-summary>
      </template>

    </a-table>
  </div>
</template>

<script setup>
import initialize from './v-table'


/*
* 表格可选修改
* 分页取消显示
* 插槽增加text
* 表单的可选可通过keys控制
* 新增自定义key值
* */
/*
* 定义表头和初始化数据的接口
* */
let props = defineProps({
  init: {
    required: false
  },
  dataSource: {
    required: false,
    default: false
  },
  pagination: {
    required: false,
    default: {}
  },
  sticky: {
    required: false,
    default: {offsetHeader: 64}
  },
  rowsKey: {
    required: false,
    default: "id"
  },
  /*
  * false 不显示可选
  * true 显示内置的可选
  * 对象 ,显示自定义的可选
  * */
  rowSelection: {
    required: false,
    default: false
  }
});


let {
  column,
  data,
  key,
  loadData,
  loadingTable,
  paginate,
  handleResizeColumn,
  selectConfig
} = initialize(props);


</script>


<style lang="scss" scoped>
.table {

  :deep(.ant-table) {

    font-family: DINPro, PingFang SC, -apple-system, BlinkMacSystemFont, Segoe UI, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", Segoe UI Symbol !important;


    .ant-table-container {

      .ant-table-content, .ant-table-body {
        @include scroll-bar;

        /* 滚动条样式 */
        &::-webkit-scrollbar {
          width: 10px !important;
          height: 10px !important;
        }
      }

      .ant-table-thead {


        & > tr {

          & > th {
            position: relative;
            color: rgba(0, 0, 0, .85);
            font-weight: 500;
            text-align: left;
            background: #edf2f2;
            border-bottom: 1px solid #f0f0f0;
            -webkit-transition: background .3s ease;
            transition: background .3s ease;
            @include overflow;
          }

          & > .ant-table-selection-column {
            text-align: center;
          }
        }
      }

      .table-striped {
        background-color: #f6f9f9;
      }

      td, th {
        font-size: $font-size-xl !important;
      }

    }

    .ant-table-column-sort {
      background: unset;
    }

  }

}

</style>