/*
* @author 友人a丶
* @date 2022-07-11
*
* 全局接口
* */

const BASE_URL = ""

export default {
    HOST: `${BASE_URL}`,//全局host
    /*用户登录*/
    login: {
        user: `${BASE_URL}/admin/login`,
        shop: `${BASE_URL}/admin/shop`,
    },
    system: {
        modify: `${BASE_URL}/systems/modify`,
        total: `${BASE_URL}/systems/total`,
        shoptotal: `${BASE_URL}/systems/shoptotal`,
        referlist: `${BASE_URL}/systems/referlist`,
    },
    user: {
        list: `${BASE_URL}/users/list`,
        add: `${BASE_URL}/users/add`,
        modify: `${BASE_URL}/users/modify`,
        del: `${BASE_URL}/users/del`,
        password: `${BASE_URL}/users/password`,
    },
    shop: {
        list: `${BASE_URL}/shops/list`,
        add: `${BASE_URL}/shops/add`,
        modify: `${BASE_URL}/shops/modify`,
        del: `${BASE_URL}/shops/del`,
        password: `${BASE_URL}/shops/password`,
    },
    index: {
        shoplist: `${BASE_URL}/app/shoplist`,
        repeatTaobao: `${BASE_URL}/app/repeatTaobao`,
        push: `${BASE_URL}/app/addPush`,
        statistic: `${BASE_URL}/app/statistic`,
    },
    order: {
        list: `${BASE_URL}/orders/list`,
        add: `${BASE_URL}/orders/add`,
        modify: `${BASE_URL}/orders/modify`,
        del: `${BASE_URL}/orders/del`,
        evaluate: `${BASE_URL}/orders/evaluate`,
        listAll: `${BASE_URL}/orders/listAll`,
        shop: `${BASE_URL}/roles/list`,
    },
    task: {
        list: `${BASE_URL}/tasks/list`,
        listAll: `${BASE_URL}/tasks/listAll`,
        add: `${BASE_URL}/tasks/add`,
        modify: `${BASE_URL}/tasks/modify`,
        del: `${BASE_URL}/tasks/del`,
        clear: `${BASE_URL}/tasks/clear`
    },
    log: {
        list: `${BASE_URL}/logs/list`
    },
    common: {
        shoplist: `${BASE_URL}/app/shoptask`,
        userlist: `${BASE_URL}/app/usertask`,
    },
    export: {
        orders: `${BASE_URL}/export/orders`,
        orders_shop: `${BASE_URL}/roles/exports`,
    },
    business:{
        checked: `${BASE_URL}/roles/checked`,
    }


}