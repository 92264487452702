/*
* @author 友人a丶
* @date 2022-07-11
* 用户退出登录
* */


import user from "@/stores/user";
import Cookies from "js-cookie";
import {router} from "@/router";
import load from "@/common/load";

/*
* @flag，是否需要弹出提示
* */
export default function (flag=true) {

    if(flag){
        load.confirm("确认退出登录吗？",()=>{
            user().$reset(); //重置用户数据的状态管理器
            /*
            * 清空cookie
            * */
            Object.keys(Cookies.get()).forEach((item)=>{
                Cookies.remove(item);
            })
            /*
            * 跳转登录界面
            * */
            router.replace('/login');
            load.error("登录状态已失效！")
        })
    }else{
        user().$reset(); //重置用户数据的状态管理器
        /*
        * 清空cookie
        * */
        Object.keys(Cookies.get()).forEach((item)=>{
            Cookies.remove(item);
        })
        /*
        * 跳转登录界面
        * */
        router.replace('/login');
        load.error("登录状态已失效！")
    }





}